@import 'zen-theming';
@import 'zen-typography';
@import 'zen-tooltips';
@import 'zen-overlays-styles';
@import 'zen-animations';

.v3-bottom-sheet {
  padding: 0 !important;
  border-radius: 16px 16px 0 0 !important;
  overflow-x: hidden !important;
  min-width: 360px !important;
}

.zen-progress-spinner {
  .mdc-circular-progress__indeterminate-container {
    .mdc-circular-progress__spinner-layer {
      .mdc-circular-progress__circle-clipper {
        .mdc-circular-progress__indeterminate-circle-graphic {
          stroke: var(--zen-neutral-300) !important;
        }
      }
    }
  }
}

.orange-bordered-radio-button {
  .mat-radio-button {
    .mat-radio-container {
      .mat-radio-outer-circle {
        border: 2px solid var(--color-orange-600) !important;
      }
    }
  }
}

.follower-drawer-panel {
  .mat-bottom-sheet-container {
    padding: 8px 16px !important;
    background: var(--zen-neutral-1400);
  }
}

.problem-report-bottom-sheet {
  .mat-bottom-sheet-container {
    background: var(--zen-neutral-1300);
    border-radius: 20px 20px 0 0;
    overflow: hidden;
    padding: unset !important;
  }
}

.cancel-project-popup-web.cdk-overlay-pane {
  .mdc-dialog__container {
    .mat-mdc-dialog-surface.mdc-dialog__surface {
      border-radius: 8px !important;
      overflow: hidden !important;
    }
  }
}

.date-slot-picker.cdk-overlay-pane {
  .mdc-dialog__container {
    .mat-mdc-dialog-surface.mdc-dialog__surface {
      border-radius: 12px !important;
      overflow: hidden !important;
    }
  }
}

.sheet-container {
  width: 976px;

  .sheet-header {
    min-height: 60px;
    max-height: 84px;
    width: 100%;
    display: flex;
    border-radius: 16px 16px 0 0;
    padding: 20px 16px 16px 16px;
    box-sizing: border-box;
    justify-items: start;
    gap: 12px;
    background-color: var(--zen-blue-1300);

    .sheet-header-icon {
      font-size: 24px;
      color: var(--zen-neutral-100);
      width: 24px;
      height: 24px;
    }

    .sheet-header-dismiss {
      color: var(--zen-neutral-1000);
      font-size: 24px;
      width: 24px;
      height: 24px;
      cursor: pointer;
    }

    .sheet-header-title {
      color: var(--zen-neutral-100);
      flex: 1;
    }

    .sheet-content {
      background-color: var(--zen-neutral-1300);
    }
  }
}

.classroom-v3-expansion-body {
  .mat-expansion-panel-body {
    padding: 0 !important;
  }

  .mat-content.mat-content-hide-toggle {
    margin: 0 !important;
  }
}

.opportunity-checklist-expansion {
  &.mat-expansion-panel {
    -webkit-tap-highlight-color: transparent;
    box-shadow: none !important;
    margin: 0 !important;

    .mat-expansion-panel-header {
      height: unset !important;
      padding: 0 !important;

      .mat-content {
        margin: 0 !important;
      }
    }

    .mat-expansion-panel-body {
      padding: 0 !important;
    }
  }
}

.zen-loading-spinner {
  .mdc-circular-progress__indeterminate-circle-graphic {
    stroke: var(--zen-neutral-100) !important;
  }
}

.classroom-form-field {
  @include zen-typo-body-small;
  color: var(--zen-neutral-100);

  .mdc-notched-outline {
    display: none;
  }

  .mdc-text-field {
    border: 1px solid var(--zen-text-100);
    border-radius: 8px !important;
    will-change: unset !important;

    textarea {
      padding: 12px 0 !important;
      height: 102px !important;
      min-height: 102px !important;
    }

    input {
      padding: 12px 0 !important;
    }
  }

  &.fill-up-box {
    color: var(--zen-neutral-500) !important;
    border: unset !important;

    .mdc-text-field {
      border: unset !important;
      padding: 0 8px !important;

      textarea {
        padding: 8px 0 !important;
      }
    }
  }

  .mat-mdc-form-field-input-control {
    color: var(--zen-text-100) !important;
  }

  .mat-mdc-select-arrow-wrapper {
    display: none !important;
  }

  .mat-mdc-form-field-flex {
    align-items: center;
  }

  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }

  .mat-mdc-input-element {
    cursor: pointer !important;
  }

  .mat-mdc-select-placeholder {
    color: var(--zen-text-800) !important;
  }

  .mat-mdc-text-field-wrapper {
    .mat-mdc-form-field-flex {
      .mat-mdc-form-field-infix {
        .mat-mdc-select-trigger {
          .mat-mdc-select-value {
            .mat-mdc-select-value-text {
              color: var(--zen-neutral-100);
            }
          }
        }
      }
    }
  }
}

.classroom-form-field-focus.mat-focused {
  .mat-mdc-text-field-wrapper.mdc-text-field {
    border: 1px solid var(--zen-blue-600) !important;
  }
}

.form-not-filled {
  .mdc-text-field {
    border: 1px solid var(--zen-neutral-800) !important;
  }
}

.language-unavailable-tooltip {
  box-sizing: border-box;
  border: 1px solid var(--zen-neutral-1100);
  transform: translateX(-11px);
  @media (max-width: 1024px) {
    transform: translateX(-42px);
  }
}

.language-unavailable-tooltip::before {
  z-index: -1 !important;
  width: 13px;
  height: 13px;
  border-top-color: var(--zen-neutral-1100) !important;
  border-left-color: var(--zen-neutral-1100) !important;
  background: var(--zen-neutral-1500);
  border-width: 1px 0 0 1px !important;
  transform: rotate(45deg) !important;
  top: -7px !important;
  @media (max-width: 1024px) {
    left: 56px !important;
  }
}

.form-selector-opened {
  .mdc-text-field {
    border: 1px solid var(--zen-blue-600) !important;
  }
}

.goodies-form {
  &.mat-form-field-invalid {
    .mdc-text-field {
      border: 1px solid var(--zen-red-600) !important;
    }
  }
}

.project-link-field {
  height: 32px;
  .mdc-text-field {
    padding: 4px 12px !important;
    overflow: hidden;

    input {
      padding: 0 !important;
    }
  }

  .mat-mdc-input-element {
    cursor: auto !important;
  }
}

.hirist-nav-icon {
  svg path {
    fill: var(--zen-neutral-100) !important;
  }
}

.zen-progress-spinner-loader {
  .mat-mdc-progress-spinner
    .mdc-circular-progress__indeterminate-circle-graphic {
    stroke: var(--zen-primary-700);
  }
}

.cdk-overlay-container
  .cdk-overlay-connected-position-bounding-box
  .cdk-overlay-pane
  .shadowed-custom-selector-panel {
  box-shadow: 0 8px 16px 0 #5a5b6a29, 0 4px 8px 0 #3a3a4429;
}

.cdk-overlay-container
  .cdk-overlay-connected-position-bounding-box
  .cdk-overlay-pane
  .cheat-days-custom-selector-panel {
  background: var(--zen-neutral-1300) !important;
  color: var(--zen-text-100) !important;
  border-radius: 12px;
  margin-top: 0px !important;
  &::-webkit-scrollbar {
    display: none;
  }
  @include zen-typo-body-small;

  .mat-mdc-option.mat-mdc-option-active:not(.mat-option-disabled) {
    background: var(--zen-neutral-1200) !important;
  }

  .mat-mdc-option:hover:not(.mat-option-disabled) {
    background: var(--zen-neutral-1200) !important;
  }

  .mdc-menu-surface.mat-mdc-select-panel {
    max-height: 260px;
  }

  .mat-pseudo-checkbox {
    display: none;
  }
}

.cdk-overlay-container
  .cdk-overlay-connected-position-bounding-box
  .cdk-overlay-pane
  .no-selection {
  .mat-mdc-option.mat-mdc-option-active:not(.mat-option-disabled) {
    background: inherit !important;
  }
}

.zen-dialog-container {
  .mat-mdc-dialog-container .mdc-dialog__surface {
    background-color: unset !important;
    border-radius: 12px !important;
  }
}

.deactivate-dialog-unique-panel {
  .mat-mdc-dialog-container .mdc-dialog__surface {
    border-radius: 12px !important;
    background-color: var(--zen-neutral-1300) !important;
  }
}

.skill-badges-onboarding-popup {
  .mat-mdc-dialog-container .mdc-dialog__surface {
    background-color: var(--zen-neutral-1300) !important;
    border-radius: 16px !important;
  }
}

.ellipsis-mat-tooltip {
  .mdc-tooltip__surface {
    background-color: var(--zen-neutral-300) !important;
    @include zen-typo-caption-medium;
    color: var(--zen-text-1400) !important;
    max-width: unset !important;
  }
}

.ellipsis-mat-multiline-tooltip {
  .mdc-tooltip__surface {
    background-color: var(--zen-neutral-300) !important;
    @include zen-typo-caption-medium;
    color: var(--zen-text-1400) !important;
    max-width: 270px !important;
  }
}

.ellipsis-mat-line-break-tooltip {
  .mdc-tooltip__surface {
    background-color: var(--zen-neutral-300) !important;
    @include zen-typo-caption-medium;
    color: var(--zen-text-1400) !important;
  }
}

@media (max-width: 1279px) {
  .sheet-container {
    width: 100%;
  }

  .deactivate-dialog-unique-panel {
    right: unset !important;
    bottom: unset !important;
    position: unset !important;
  }

  .classroom-form-field {
    &.unset-mobile-height {
      .mat-mdc-form-field-flex {
        height: unset;
      }
    }

    .mat-mdc-form-field-flex {
      height: 40px;
    }

    .mdc-text-field {
      textarea {
        padding: 12px 0 !important;
        height: 102px !important;
        min-height: 102px !important;
      }
    }
  }
}

.zen-radio-small {
  .mdc-radio {
    padding: 0 !important;

    .mat-mdc-radio-touch-target {
      width: 20px !important;
      height: 20px !important;
    }

    .mdc-radio__native-control {
      width: 20px !important;
      height: 20px !important;
    }

    .mdc-radio__background {
      scale: 0.7;

      .mdc-radio__outer-circle {
        border-color: currentColor !important;
      }

      .mdc-radio__inner-circle {
        border-color: currentColor !important;
      }
    }
  }

  label {
    white-space: nowrap !important;
  }

  &.new-mcq-options {
    .mdc-radio__background {
      .mdc-radio__outer-circle {
        opacity: unset !important;
      }

      .mdc-radio__inner-circle {
        opacity: unset !important;
      }
    }
  }
}

.zen-radio-resume {
  .mdc-radio {
    padding: 0 !important;

    .mat-mdc-radio-touch-target {
      width: 24px !important;
      height: 24px !important;
    }

    .mdc-radio__native-control {
      width: 24px !important;
      height: 24px !important;
    }

    .mdc-radio__background {
      scale: 0.7;

      .mdc-radio__outer-circle {
        border-color: currentColor !important;
      }

      .mdc-radio__inner-circle {
        border-color: currentColor !important;
      }
    }
  }

  label {
    white-space: nowrap !important;
  }

  &.new-mcq-options {
    .mdc-radio__background {
      .mdc-radio__outer-circle {
        opacity: unset !important;
      }

      .mdc-radio__inner-circle {
        opacity: unset !important;
      }
    }
  }
}

.zen-radio-medium {
  scale: 0.8;

  .mdc-radio {
    padding: 5px 0 !important;

    .mat-mdc-radio-touch-target {
      width: 24px !important;
      height: 24px !important;
    }

    .mdc-radio__native-control {
      width: 24px !important;
      height: 24px !important;
    }

    .mdc-radio__background {
      .mdc-radio__outer-circle {
        border-color: var(--zen-neutral-100) !important;
      }

      .mdc-radio__inner-circle {
        border-color: var(--zen-neutral-100) !important;
      }
    }
  }

  label {
    white-space: nowrap !important;
  }
}

.zen-checkbox-small {
  .mdc-checkbox {
    height: 20px !important;
  }

  &.mat-mdc-checkbox-checked {
    .mdc-checkbox__background {
      background-color: var(--zen-text-100) !important;

      .mdc-checkbox__checkmark {
        color: var(--zen-neutral-1300) !important;
      }
    }
  }

  &.mat-mdc-checkbox-disabled {
    .mdc-checkbox {
      .mdc-checkbox__background {
        border-color: var(--zen-text-1000) !important;
      }
    }
  }

  .mdc-checkbox {
    padding: 0 !important;

    .mat-mdc-checkbox-touch-target {
      width: 20px !important;
      height: 20px !important;
    }

    .mdc-checkbox__native-control {
      width: 20px !important;
      height: 20px !important;
    }

    .mdc-checkbox__background {
      top: unset !important;
      left: unset !important;
      scale: 0.7;
      border-color: var(--zen-text-100) !important;

      .mdc-checkbox__checkmark {
        color: var(--zen-neutral-1300) !important;
      }
    }

    .mat-mdc-checkbox-ripple {
      display: none;
    }
  }
}

.zen-checkbox-large {
  .mdc-checkbox {
    height: 24px !important;
  }

  &.mat-mdc-checkbox-checked {
    .mdc-checkbox__background {
      background-color: var(--zen-text-100) !important;

      .mdc-checkbox__checkmark {
        color: var(--zen-neutral-1300) !important;
      }
    }
  }

  &.mat-mdc-checkbox-disabled {
    .mdc-checkbox {
      .mdc-checkbox__background {
        border-color: var(--zen-text-1000) !important;
      }
    }
  }

  .mdc-checkbox {
    padding: 0 !important;

    .mat-mdc-checkbox-touch-target {
      width: 24px !important;
      height: 24px !important;
    }

    .mdc-checkbox__native-control {
      width: 24px !important;
      height: 24px !important;
    }

    .mdc-checkbox__background {
      top: unset !important;
      left: unset !important;
      scale: 0.7;
      border-color: var(--zen-text-100) !important;

      .mdc-checkbox__checkmark {
        color: var(--zen-neutral-1300) !important;
      }
    }

    .mat-mdc-checkbox-ripple {
      display: none;
    }
  }
}

.zen-checkbox-small-mcq {
  .mdc-checkbox {
    height: 20px !important;
  }

  &.mat-mdc-checkbox-disabled {
    .mdc-checkbox {
      .mdc-checkbox__background {
        border-color: var(--zen-text-1000) !important;
      }
    }
  }

  .mdc-checkbox {
    padding: 0 !important;

    .mat-mdc-checkbox-touch-target {
      width: 20px !important;
      height: 20px !important;
    }

    .mdc-checkbox__native-control {
      width: 20px !important;
      height: 20px !important;
    }

    .mdc-checkbox__background {
      scale: 0.7;
      border-color: var(--zen-neutral-700) !important;
      position: unset !important;

      .mdc-checkbox__checkmark {
        color: var(--zen-neutral-1300) !important;
      }
    }

    .mat-mdc-checkbox-ripple {
      display: none;
    }
  }

  &.mat-mdc-checkbox-checked {
    .mdc-checkbox {
      .mdc-checkbox__background {
        .mdc-checkbox__checkmark {
          color: var(--zen-neutral-1300) !important;
        }
      }
    }
  }
}

.zen-checkbox-small-mcq-wrong {
  &.mat-mdc-checkbox-checked {
    .mdc-checkbox {
      .mdc-checkbox__background {
        background-color: var(--zen-red-600) !important;
        border-color: var(--zen-red-600) !important;
      }
    }
  }
}

.zen-checkbox-small-mcq-right {
  &.mat-mdc-checkbox-checked {
    .mdc-checkbox {
      .mdc-checkbox__background {
        background-color: var(--zen-green-600) !important;
        border-color: var(--zen-green-600) !important;
      }
    }
  }
}

.zen-checkbox-small-mcq-default {
  &.mat-mdc-checkbox-checked {
    .mdc-checkbox {
      .mdc-checkbox__background {
        background-color: var(--zen-text-100) !important;
        border-color: var(--zen-text-100) !important;
      }
    }
  }
}

.v3-progress-bar {
  height: 8px !important;

  .mdc-linear-progress__bar {
    .mdc-linear-progress__bar-inner {
      border-top-width: 8px !important;
      border-color: var(--zen-blue-600) !important;
    }
  }
}

@keyframes zenShimmer {
  100% {
    -webkit-mask-position: left;
  }
}

@keyframes zenRevealContent {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
  }
}

@keyframes zenBounce {
  0% {
    transform: translateY(0);
  }
  5.55556% {
    transform: translateY(0);
  }
  11.11111% {
    transform: translateY(0);
  }
  22.22222% {
    transform: translateY(-15px);
  }
  27.77778% {
    transform: translateY(0);
  }
  33.33333% {
    transform: translateY(-15px);
  }
  44.44444% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes zenBounceSmooth {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-7px);
  }
  60% {
    transform: translateY(-2px);
  }
}

@keyframes zenShineShimmerSmall {
  0% {
    left: -50%;
  }
  70% {
    left: 100%;
  }
  100% {
    left: 100%;
  }
}

@keyframes zenShineShimmerLarge {
  0% {
    left: calc(-50% + 9px);
  }
  70% {
    left: calc(100% + 9px);
  }
  100% {
    left: calc(100% + 9px);
  }
}

.zen-shimmer {
  background-color: var(--zen-neutral-1500);
  -webkit-mask: linear-gradient(-60deg, #000 30%, #0005, #000 70%) right/300%
    100%;
  background-repeat: no-repeat;
  display: inline-block;
  position: relative;

  animation: zenShimmer 1s infinite linear;
}

.zen-shimmer-text {
  -webkit-mask: linear-gradient(-60deg, #000 10%, #0005, #000 90%) right/300%
    100%;
  animation: zenShimmer 1s infinite linear;
}

.zen-shine-shimmer {
  position: relative;
  overflow: hidden !important;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    height: 100%;
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(87, 128, 233, 0.22) 100%
    );
    filter: blur(0.5px);
    transform: skewX(-20deg);
  }

  &:before {
    width: 5px;
    animation: zenShineShimmerSmall linear 2s both infinite;
    animation-timing-function: ease-out;
  }

  &:after {
    width: 15px;
    animation: zenShineShimmerLarge linear 2s both infinite;
    animation-timing-function: ease-out;
  }
}

.zen-shine-shimmer-fast {
  position: relative;
  overflow: hidden !important;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    height: 100%;
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.4) 100%
    );
    filter: blur(0.5px);
    transform: skewX(-20deg);
  }

  &:before {
    width: 5px;
    animation: zenFastShimmerSmall linear 2.5s both infinite;
  }

  &:after {
    width: 15px;
    animation: zenFastShimmerLarge linear 2.5s both infinite;
  }
}

.classroom-v3-mat-tooltip {
  .mdc-tooltip__surface {
    background: var(--zen-neutral-300) !important;
    color: var(--zen-text-1400) !important;
    @include zen-typo-caption-medium;
  }
}

.penalty-dialog {
  .mat-bottom-sheet-container {
    background: unset !important;
    box-shadow: unset !important;
  }
}

.zen-tooltip-caret-small::before {
  height: 10px;
  width: 10px;
  transform: rotate(-225deg) !important;
  border: 1px solid #141414 !important;
  background: #141414;
}

.zen-tooltip-bordered-caret-top::before {
  z-index: 1 !important;
  width: 10px;
  height: 10px;
  border-top-color: var(--zen-neutral-1100) !important;
  border-left-color: var(--zen-neutral-1100) !important;

  &.unified-profile-popover {
    border-top-color: var(--zen-neutral-1200) !important;
    border-left-color: var(--zen-neutral-1200) !important;
  }

  background: currentColor;
  border-width: 1px 0 0 1px !important;
}

.zen-tooltip-bordered-caret-bottom::before {
  z-index: 1 !important;
  width: 10px;
  height: 10px;
  border-bottom-color: var(--zen-neutral-1100) !important;
  border-right-color: var(--zen-neutral-1100) !important;
  background: var(--zen-neutral-1500);
  border-width: 0 1px 1px 0 !important;
}

.mat-form-field-error {
  .mat-mdc-form-field-hint-wrapper,
  .mat-mdc-form-field-error-wrapper {
    padding: unset !important;
  }

  .mdc-text-field {
    border: 1px solid var(--zen-red-600) !important;
  }
}

.zen-account-form-field {
  .mat-form-field-outline {
    height: 56px !important;
  }

  .mat-form-field-infix {
    padding: 0.5em 0 1.5em 0 !important;
  }
}

.classroom-goodies {
  .declaration {
    width: 100%;

    .mat-checkbox-frame {
      border-color: var(--zen-neutral-100) !important;
    }
  }
}

.zen-unselect {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
}

.zen-scrollbars {
  /* For Chrome, Safari, and Opera */
  ::-webkit-scrollbar {
    width: 12px; /* width of the entire scrollbar */
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1; /* color of the tracking area */
  }

  ::-webkit-scrollbar-thumb {
    background: #888; /* color of the scroll thumb */
    border-radius: 10px; /* roundness of the scroll thumb */
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555; /* color of the scroll thumb on hover */
  }

  /* For Firefox */
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

.nps-form-bottom-sheet.zen-bottom-sheet-dialog.cdk-overlay-pane {
  min-width: 976px !important;
  margin: 0 auto;
}

.opportunity-info-bottom-sheet {
  .mat-bottom-sheet-container {
    border-radius: 16px 16px 0 0;
    border-width: 1px 1px 0 1px;
    border-style: solid;
    border-color: var(--zen-neutral-1200);
    box-shadow: 0px -13px 16px 0px #0000000f;
  }
}

.classroom-text-area {
  .mat-mdc-form-field {
    all: initial;
  }

  .mdc-line-ripple::before {
    border-bottom-style: none;
  }
  .mdc-line-ripple::after {
    border: none;
  }

  textarea::placeholder {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--zen-text-800);
  }

  .mdc-text-field {
    background: var(--zen-neutral-1300);
    border: 1px solid var(--zen-neutral-100);
    border-radius: 8px;
    padding: 12px 14px;
  }

  .mdc-text-field--focused {
    border: 1px solid var(--zen-blue-600) !important;
  }

  .mdc-text-field--filled.mdc-text-field--no-label .mdc-text-field__input {
    color: var(--zen-text-100);
  }
}

.interested-text-area {
  .mdc-checkbox {
    display: flex;
    align-items: center;
  }

  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
}

.empty-text-area {
  .mdc-text-field {
    border-color: var(--zen-neutral-1000);
  }
}

.web-loader-dots {
  .loading-dots__dot {
    width: 2.5px !important;
    height: 2.5px !important;
  }
}

.awards-popup-unique-panel {
  .mat-mdc-dialog-container .mdc-dialog__surface {
    box-shadow: unset !important;
  }
}

.awards-link-popover {
  min-height: unset !important;
}

.custom-expansion-panel {
  .mat-expansion-panel-body {
    padding: 0 14px 16px;

    .mat-expansion-panel {
      box-shadow: none;

      .mat-expansion-panel-header {
        padding-left: 0 !important;
        width: 280px;
      }
    }
  }
}

.project-evaluation .custom-expansion-panel {
  .mat-expansion-panel-body {
    margin-top: 4px;
  }
}

.custom-expansion-panel {
  .mat-expansion-panel-body {
    padding: unset !important;
  }

  .mat-expansion-indicator {
    margin-bottom: 4px;
  }
}

.submission-selector {
  .mat-mdc-form-field.mat-custom-selector-field {
    width: 100%;
    color: var(--zen-neutral-800);

    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }

    .mat-mdc-text-field-wrapper.mdc-text-field {
      border: 1px solid var(--zen-neutral-1100);
    }

    .mat-mdc-form-field-icon-suffix {
      .mat-icon {
        padding: 8px 12px;
      }
    }
  }
}

#zmmtg-root {
  z-index: 1000;
}

.feedback-dropdown-selector {
  .mat-mdc-form-field.mat-custom-selector-field {
    width: 100%;
    color: var(--zen-text-800);

    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }

    .mat-mdc-text-field-wrapper.mdc-text-field {
      border: 1px solid var(--zen-neutral-1000);
      background: var(--zen-neutral-1300);
      padding-left: 12px !important;
    }

    .mat-mdc-form-field-icon-suffix {
      .mat-icon {
        padding: 8px 12px;
      }
    }
  }
}

.cdk-overlay-container
  .cdk-overlay-connected-position-bounding-box
  .cdk-overlay-pane
  .extendend-drop-class {
  padding: unset !important;
  max-height: 300px !important;
  min-width: 300px !important;
}

.cdk-overlay-container
  .cdk-overlay-connected-position-bounding-box
  .cdk-overlay-pane
  .mat-custom-selector-panel {
  border-radius: 8px !important;
  border: 1px solid var(--zen-neutral-1200);
  margin-top: 4px !important;
  &.mdc-menu-surface.mat-mdc-select-panel {
    padding: unset !important;
    max-height: 260px;
    min-width: 300px;
  }

  .mat-pseudo-checkbox {
    display: none;
  }
  &::-webkit-scrollbar {
    display: none;
  }

  .mat-mdc-option.mdc-list-item {
    border-bottom: 1px solid var(--zen-neutral-1200);
  }
}

.web-dev-test-cases .custom-expansion-panel,
.project-evaluation .custom-expansion-panel {
  .mat-expansion-indicator {
    display: none;
  }

  .mat-expansion-panel-header-title {
    margin-right: 0;
    width: 100%;
  }
}

.web-dev-submission-status {
  .mat-mdc-progress-spinner {
    circle {
      stroke: var(--zen-green-600);
    }
  }
}

.as-split-gutter {
  background-color: var(--zen-neutral-1500) !important;
}

.as-split-gutter.as-horizontal {
  background-color: var(--zen-neutral-1500) !important;
  .as-split-gutter-icon {
    background-image: url('https://files.codingninjas.in/split-gutter-grey-27599.svg') !important;
    background-color: var(--zen-neutral-1500) !important;
  }
}

.as-split-gutter.as-vertical {
  background-color: var(--zen-neutral-1500) !important;
  .as-split-gutter-icon {
    background-image: url('https://files.codingninjas.in/split-gutter-grey-rotated-33465.svg') !important;
    background-color: var(--zen-neutral-1500) !important;
  }
}

.as-split-gutter.as-dragged.as-horizontal .as-split-gutter-icon,
.as-split-gutter.as-horizontal .as-split-gutter-icon:hover {
  background-image: url('https://files.codingninjas.in/rectangle-horizontal-33463.svg') !important;
  background-color: var(--zen-neutral-1500) !important;
}

.as-split-gutter.as-dragged.as-vertical .as-split-gutter-icon,
.as-split-gutter.as-vertical .as-split-gutter-icon:hover {
  background-image: url('https://files.codingninjas.in/rectangle-vertical-33464.svg') !important;
  background-color: var(--zen-neutral-1500) !important;
}

.onboarding-billing-address-panel {
  position: relative !important;
  animation: slide-bounce-right 1s cubic-bezier(0.215, 0.61, 0.355, 1);
  &.onboarding-billing-address-panel-mobile {
    animation: slide-bounce-up 1s cubic-bezier(0.215, 0.61, 0.355, 1);
    .mat-mdc-dialog-container .mdc-dialog__surface {
      border-radius: 20px 20px 0 0 !important;
    }
  }
  .mat-mdc-dialog-container .mdc-dialog__surface {
    border-radius: 20px 0 0 20px !important;
  }
}

.onboarding-billing-address-backdrop {
  pointer-events: none;
}

.ga-tooltip {
  .mdc-tooltip__surface {
    position: relative !important;
    right: 68px !important;
    top: 17px !important;
  }
}

.ga-onboarding-popover {
  &.caret-popover-rounded.popover-bottom-left::before {
    right: 14px !important;
    top: 1px !important;
    border-color: var(--zen-neutral-200) !important;
  }
}

.disabled-interview-form .mat-mdc-form-field-icon-prefix,
.mat-mdc-form-field-icon-suffix {
  pointer-events: none !important;
}
