body {
  --zen-neutral-100: #141414;
  --zen-neutral-200: #1a1a1a;
  --zen-neutral-300: #1f1f1f;
  --zen-neutral-400: #2d2d2d;
  --zen-neutral-500: #403f3f;
  --zen-neutral-600: #5e5f60;
  --zen-neutral-700: #717172;
  --zen-neutral-800: #838485;
  --zen-neutral-900: #999999;
  --zen-neutral-1000: #adadae;
  --zen-neutral-1100: #c3c3c3;
  --zen-neutral-1200: #e3e3e3;
  --zen-neutral-1300: #ffffff;
  --zen-neutral-1400: #f8f8f8;
  --zen-neutral-1500: #f1f2f2;

  --zen-primary-25: #2f2c31;
  --zen-primary-50: #352e32;
  --zen-primary-100: #483433;
  --zen-primary-200: #5c3b34;
  --zen-primary-300: #714135;
  --zen-primary-400: #9a4e37;
  --zen-primary-500: #c35c39;
  --zen-primary-600: #f66c3b;
  --zen-primary-700: #f8916c;
  --zen-primary-800: #faae93;
  --zen-primary-900: #fcccba;
  --zen-primary-1000: #fddace;
  --zen-primary-1100: #fee9e2;
  --zen-primary-1200: #fff6f3;
  --zen-primary-1300: #fffbf9;

  --zen-blue-25: #2a2d36;
  --zen-blue-50: #2b303c;
  --zen-blue-100: #30374d;
  --zen-blue-200: #35405f;
  --zen-blue-300: #394871;
  --zen-blue-400: #425996;
  --zen-blue-500: #4c6bbb;
  --zen-blue-600: #5780e9;
  --zen-blue-700: #81a0ef;
  --zen-blue-800: #a3b9f3;
  --zen-blue-900: #c4d3f7;
  --zen-blue-1000: #d5dff9;
  --zen-blue-1100: #e6ecfc;
  --zen-blue-1200: #f5f7fe;
  --zen-blue-1300: #fafbfe;

  --zen-purple-25: #2a2c35;
  --zen-purple-50: #2c2d39;
  --zen-purple-100: #322f47;
  --zen-purple-200: #383156;
  --zen-purple-300: #3d3465;
  --zen-purple-400: #483b82;
  --zen-purple-500: #5341a0;
  --zen-purple-600: #6249c5;
  --zen-purple-700: #8977d4;
  --zen-purple-800: #a99bdf;
  --zen-purple-900: #c8bfeb;
  --zen-purple-1000: #d8d1f0;
  --zen-purple-1100: #e8e4f6;
  --zen-purple-1200: #f6f4fb;
  --zen-purple-1300: #fafafd;

  --zen-red-25: #2e2d31;
  --zen-red-50: #352e33;
  --zen-red-100: #483337;
  --zen-red-200: #5d383c;
  --zen-red-300: #713e3f;
  --zen-red-400: #994a47;
  --zen-red-500: #c2564f;
  --zen-red-600: #f6655a;
  --zen-red-700: #f88c83;
  --zen-red-800: #faaaa4;
  --zen-red-900: #fcc9c5;
  --zen-red-1000: #fdd8d6;
  --zen-red-1100: #fee8e6;
  --zen-red-1200: #fff6f5;
  --zen-red-1300: #fffafa;

  --zen-yellow-25: #2f2f31;
  --zen-yellow-50: #353432;
  --zen-yellow-100: #494135;
  --zen-yellow-200: #5f4f37;
  --zen-yellow-300: #745e39;
  --zen-yellow-400: #9e7d3d;
  --zen-yellow-500: #c99b41;
  --zen-yellow-600: #ffc147;
  --zen-yellow-700: #ffd175;
  --zen-yellow-800: #ffdd9a;
  --zen-yellow-900: #ffe9bf;
  --zen-yellow-1000: #ffefd1;
  --zen-yellow-1100: #fff6e4;
  --zen-yellow-1200: #fffbf4;
  --zen-yellow-1300: #fffdfa;

  --zen-green-25: #2a2f32;
  --zen-green-50: #2c3334;
  --zen-green-100: #323e39;
  --zen-green-200: #384b3f;
  --zen-green-300: #3e5944;
  --zen-green-400: #49744f;
  --zen-green-500: #568f5a;
  --zen-green-600: #65b168;
  --zen-green-700: #8cc58e;
  --zen-green-800: #aad4ac;
  --zen-green-900: #c9e4ca;
  --zen-green-1000: #d8ebd9;
  --zen-green-1100: #e8f3e8;
  --zen-green-1200: #f6faf6;
  --zen-green-1300: #fafdfa;

  --zen-teal-25: #172629;
  --zen-teal-50: #192a2e;
  --zen-teal-100: #143134;
  --zen-teal-200: #023737;
  --zen-teal-300: #034545;
  --zen-teal-400: #035253;
  --zen-teal-500: #1b7e7e;
  --zen-teal-600: #33a9a9;
  --zen-teal-700: #60bcbc;
  --zen-teal-800: #8dcfcf;
  --zen-teal-900: #bbe2e2;
  --zen-teal-1000: #d1ebec;
  --zen-teal-1100: #e8f5f5;
  --zen-teal-1200: #f6fbfb;
  --zen-teal-1300: #fafdfd;

  --zen-pink-25: #2e2c33;
  --zen-pink-50: #342e36;
  --zen-pink-100: #473240;
  --zen-pink-200: #5b3849;
  --zen-pink-300: #6e3e53;
  --zen-pink-400: #964966;
  --zen-pink-500: #bd547a;
  --zen-pink-600: #ef6292;
  --zen-pink-700: #f389ad;
  --zen-pink-800: #f6a9c3;
  --zen-pink-900: #f9c8d9;
  --zen-pink-1000: #fbd8e4;
  --zen-pink-1100: #fde7ef;
  --zen-pink-1200: #fef5f9;
  --zen-pink-1300: #fffafc;

  --zen-aqua-25: #292f36;
  --zen-aqua-50: #2a333d;
  --zen-aqua-100: #2d3e50;
  --zen-aqua-200: #2f4b65;
  --zen-aqua-300: #315979;
  --zen-aqua-400: #3673a2;
  --zen-aqua-500: #3b8ecb;
  --zen-aqua-600: #41afff;
  --zen-aqua-700: #71c3ff;
  --zen-aqua-800: #96d3ff;
  --zen-aqua-900: #bce3ff;
  --zen-aqua-1000: #cfebff;
  --zen-aqua-1100: #e3f3ff;
  --zen-aqua-1200: #f4faff;
  --zen-aqua-1300: #f9fdff;

  --zen-text-100: #141414;
  --zen-text-400: #7f7f7f;
  --zen-text-800: #979797;
  --zen-text-1000: #bbbbbb;
  --zen-text-1400: #fafafa;

  --zen-shadow-100: #f0f0f0;
  --zen-bg: #ffffff;
  --zen-grey-bg: #f8f8f8;
  --zen-bg-nav: #ffffff;
  --zen-bg-divider: #f8f8f8;
  --zen-grey-900: #292a31;
}

.zen-dark-ui {
  --zen-neutral-100: #f1f2f2;
  --zen-neutral-200: #f8f8f8;
  --zen-neutral-300: #ffffff;
  --zen-neutral-400: #e3e3e3;
  --zen-neutral-500: #c3c3c3;
  --zen-neutral-600: #adadae;
  --zen-neutral-700: #999999;
  --zen-neutral-800: #838485;
  --zen-neutral-900: #787a7b;
  --zen-neutral-1000: #5e5f60;
  --zen-neutral-1100: #403f3f;
  --zen-neutral-1200: #2d2d2d;
  --zen-neutral-1300: #1f1f1f;
  --zen-neutral-1400: #1a1a1a;
  --zen-neutral-1500: #141414;

  --zen-primary-25: #fffbf9;
  --zen-primary-50: #fff6f3;
  --zen-primary-100: #fee9e2;
  --zen-primary-200: #fddace;
  --zen-primary-300: #fcccba;
  --zen-primary-400: #faae93;
  --zen-primary-500: #f8916c;
  --zen-primary-600: #f66c3b;
  --zen-primary-700: #c35c39;
  --zen-primary-800: #9a4e37;
  --zen-primary-900: #714135;
  --zen-primary-1000: #5c3b34;
  --zen-primary-1100: #483433;
  --zen-primary-1200: #352e32;
  --zen-primary-1300: #2f2c31;

  --zen-blue-25: #fafbfe;
  --zen-blue-50: #f5f7fe;
  --zen-blue-100: #e6ecfc;
  --zen-blue-200: #d5dff9;
  --zen-blue-300: #c4d3f7;
  --zen-blue-400: #a3b9f3;
  --zen-blue-500: #81a0ef;
  --zen-blue-600: #5780e9;
  --zen-blue-700: #4c6bbb;
  --zen-blue-800: #425996;
  --zen-blue-900: #394871;
  --zen-blue-1000: #35405f;
  --zen-blue-1100: #30374d;
  --zen-blue-1200: #2b303c;
  --zen-blue-1300: #2a2d36;

  --zen-purple-25: #fafafd;
  --zen-purple-50: #f6f4fb;
  --zen-purple-100: #e8e4f6;
  --zen-purple-200: #d8d1f0;
  --zen-purple-300: #c8bfeb;
  --zen-purple-400: #a99bdf;
  --zen-purple-500: #8977d4;
  --zen-purple-600: #6249c5;
  --zen-purple-700: #5341a0;
  --zen-purple-800: #483b82;
  --zen-purple-900: #3d3465;
  --zen-purple-1000: #383156;
  --zen-purple-1100: #322f47;
  --zen-purple-1200: #2c2d39;
  --zen-purple-1300: #2a2c35;

  --zen-red-25: #fffafa;
  --zen-red-50: #fff6f5;
  --zen-red-100: #fee8e6;
  --zen-red-200: #fdd8d6;
  --zen-red-300: #fcc9c5;
  --zen-red-400: #faaaa4;
  --zen-red-500: #f88c83;
  --zen-red-600: #f6655a;
  --zen-red-700: #c2564f;
  --zen-red-800: #994a47;
  --zen-red-900: #713e3f;
  --zen-red-1000: #5d383c;
  --zen-red-1100: #483337;
  --zen-red-1200: #352e33;
  --zen-red-1300: #2e2d31;

  --zen-yellow-25: #fffdfa;
  --zen-yellow-50: #fffbf4;
  --zen-yellow-100: #fff6e4;
  --zen-yellow-200: #ffefd1;
  --zen-yellow-300: #ffe9bf;
  --zen-yellow-400: #ffdd9a;
  --zen-yellow-500: #ffd175;
  --zen-yellow-600: #ffc147;
  --zen-yellow-700: #c99b41;
  --zen-yellow-800: #9e7d3d;
  --zen-yellow-900: #745e39;
  --zen-yellow-1000: #5f4f37;
  --zen-yellow-1100: #494135;
  --zen-yellow-1200: #353432;
  --zen-yellow-1300: #2f2f31;

  --zen-green-25: #fafdfa;
  --zen-green-50: #f6faf6;
  --zen-green-100: #e8f3e8;
  --zen-green-200: #d8ebd9;
  --zen-green-300: #c9e4ca;
  --zen-green-400: #aad4ac;
  --zen-green-500: #8cc58e;
  --zen-green-600: #65b168;
  --zen-green-700: #568f5a;
  --zen-green-800: #49744f;
  --zen-green-900: #3e5944;
  --zen-green-1000: #384b3f;
  --zen-green-1100: #323e39;
  --zen-green-1200: #2c3334;
  --zen-green-1300: #2a2f32;

  --zen-teal-25: #fafdfd;
  --zen-teal-50: #f6fbfb;
  --zen-teal-100: #e8f5f5;
  --zen-teal-200: #d1ebec;
  --zen-teal-300: #bbe2e2;
  --zen-teal-400: #8dcfcf;
  --zen-teal-500: #60bcbc;
  --zen-teal-600: #33a9a9;
  --zen-teal-700: #1b7e7e;
  --zen-teal-800: #035253;
  --zen-teal-900: #034545;
  --zen-teal-1000: #023737;
  --zen-teal-1100: #143134;
  --zen-teal-1200: #192a2e;
  --zen-teal-1300: #172629;

  --zen-pink-25: #fffafc;
  --zen-pink-50: #fef5f9;
  --zen-pink-100: #fde7ef;
  --zen-pink-200: #fbd8e4;
  --zen-pink-300: #f9c8d9;
  --zen-pink-400: #f6a9c3;
  --zen-pink-500: #f389ad;
  --zen-pink-600: #ef6292;
  --zen-pink-700: #bd547a;
  --zen-pink-800: #964966;
  --zen-pink-900: #6e3e53;
  --zen-pink-1000: #5b3849;
  --zen-pink-1100: #473240;
  --zen-pink-1200: #342e36;
  --zen-pink-1300: #2e2c33;

  --zen-aqua-25: #f9fdff;
  --zen-aqua-50: #f4faff;
  --zen-aqua-100: #e3f3ff;
  --zen-aqua-200: #cfebff;
  --zen-aqua-300: #bce3ff;
  --zen-aqua-400: #96d3ff;
  --zen-aqua-500: #71c3ff;
  --zen-aqua-600: #41afff;
  --zen-aqua-700: #3b8ecb;
  --zen-aqua-800: #3673a2;
  --zen-aqua-900: #315979;
  --zen-aqua-1000: #2f4b65;
  --zen-aqua-1100: #2d3e50;
  --zen-aqua-1200: #2a333d;
  --zen-aqua-1300: #292f36;

  --zen-text-100: #fafafa;
  --zen-text-400: #bbbbbb;
  --zen-text-800: #979797;
  --zen-text-1000: #7f7f7f;
  --zen-text-1400: #141414;

  --zen-shadow-100: #1e2023;

  --zen-bg: #141414;
  --zen-grey-bg: #141414;
  --zen-bg-nav: #1a1a1a;
  --zen-bg-divider: #1a1a1a;

  .zen-blank-state-container {
    .zen-blank-state-illustration {
      opacity: 0.25 !important;
    }
  }
}
