@mixin zen-typo-common {
  letter-spacing: 0;
}

@mixin zen-typo-heading-1 {
  font-size: 44px;
  font-weight: 700;
  line-height: 60px;
}

@mixin zen-typo-heading-1-small {
  font-weight: 700;
  font-size: 32px;
  line-height: 42px;
}

.zen-typo-heading-1 {
  @include zen-typo-common;
  @include zen-typo-heading-1;
  @media (max-width: 980px) {
    @include zen-typo-heading-1-small;
  }
}

@mixin zen-typo-heading-2 {
  font-size: 32px;
  font-weight: 700;
  line-height: 42px;
}

@mixin zen-typo-heading-2-small {
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;
}

.zen-typo-heading-2 {
  @include zen-typo-common;
  @include zen-typo-heading-2;
  @media (max-width: 980px) {
    @include zen-typo-heading-2-small;
  }
}

@mixin zen-typo-heading-3 {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
}

.zen-typo-heading-3 {
  @include zen-typo-common;
  @include zen-typo-heading-3;
}

@mixin zen-typo-heading-4 {
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
}

.zen-typo-heading-4 {
  @include zen-typo-common;
  @include zen-typo-heading-4;
}

@mixin zen-typo-heading-5 {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
}

.zen-typo-heading-5 {
  @include zen-typo-common;
  @include zen-typo-heading-5;
}

@mixin zen-typo-body-large {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.zen-typo-body-large {
  @include zen-typo-common;
  @include zen-typo-body-large;
}

@mixin zen-typo-body-small {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.zen-typo-body-small {
  @include zen-typo-common;
  @include zen-typo-body-small;
}

@mixin zen-typo-nav {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}

.zen-typo-nav {
  @include zen-typo-common;
  @include zen-typo-nav;
}

@mixin zen-typo-pills {
  font-weight: 700;
  font-size: 12px;
  line-height: 12px;
}

.zen-typo-pills {
  @include zen-typo-common;
  @include zen-typo-pills;
}

@mixin zen-typo-subtitle-large {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}

.zen-typo-subtitle-large {
  @include zen-typo-common;
  @include zen-typo-subtitle-large;
}

@mixin zen-typo-subtitle-small {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
}

.zen-typo-subtitle-small {
  @include zen-typo-common;
  @include zen-typo-subtitle-small;
}

@mixin zen-typo-caption-bold {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
}

.zen-typo-caption-bold {
  @include zen-typo-common;
  @include zen-typo-caption-bold;
}

@mixin zen-typo-caption-medium {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
}

.zen-typo-caption-medium {
  @include zen-typo-common;
  @include zen-typo-caption-medium;
}

@mixin zen-typo-smallest-bold {
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
}

.zen-typo-smallest-bold {
  @include zen-typo-common;
  @include zen-typo-smallest-bold;
}

@mixin zen-typo-smallest-medium {
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
}

.zen-typo-smallest-medium {
  @include zen-typo-common;
  @include zen-typo-smallest-medium;
}
