@use '@angular/material' as mat;
// Import Fuse core library
@import '@fuse/scss/core';

// Import app.theme.scss
@import 'app/app.theme';

@import '../../../libs/ninjas-utils/src/lib/zen-components/zen-styles';

.spinner_small {
  height: 24px !important;
  width: 24px !important;
}

.spinner_small > svg {
  width: 24px !important;
  height: 24px !important;
}

.spinner_medium {
  height: 45px !important;
  width: 45px !important;
}

.spinner_medium > svg {
  width: 45px !important;
  height: 45px !important;
}

.mat-tooltip.tashipCertificateTooltip {
  font-size: 14px;
}

.overtime-slot-modal .mat-dialog-container {
  padding: 0 !important;
  border-radius: 12px !important;

  &::-webkit-scrollbar {
    display: none;
  }
}

.cdk-overlay-container .cdk-overlay-pane .custom-mat-selector-panel {
  top: 36px;
  position: relative;
  left: 8px;
}

.custom-mat-selector-field {
  .mat-form-field-outline-thick {
    color: var(--zen-text-400) !important;
  }

  .mat-select-arrow-wrapper {
    display: none !important;
  }

  .mat-form-field-infix {
    align-items: center;
  }
}
