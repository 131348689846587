@import 'zen-typography';

.header-tooltips {
  .mdc-tooltip__surface {
    white-space: pre-wrap !important;
    color: var(--zen-text-400) !important;
    background: var(--zen-neutral-1400) !important;
    border: 1px solid var(--zen-neutral-1100) !important;
    @include zen-typo-caption-medium;
    overflow: unset !important;
    padding: 8px !important;
    border-radius: 8px !important;
    width: max-content !important;
  }

  &.right {
    .mdc-tooltip__surface {
      position: absolute;
      transform: translate(0%, -50%) !important;
    }
  }

  &.white-tooltip {
    .mdc-tooltip__surface {
      background: var(--zen-neutral-1300) !important;
    }
  }

  &.unified-profile {
    .mdc-tooltip__surface {
      background: var(--zen-neutral-1300) !important;
      border: 1px solid var(--zen-neutral-1200);
      box-shadow: 0 12px 32px 0 #ededed !important;
    }
  }
}

@supports ((-webkit-clip-path: inset(50%)) or (clip-path: inset(50%))) {
  .header-tooltips {
    .mdc-tooltip__surface::after {
      content: '' !important;
      display: block !important;
      height: 11px !important;
      width: 11px !important;
      background: inherit !important;
      top: -11px !important;
      border: inherit !important;
      position: absolute !important;
      -webkit-clip-path: polygon(0% 0%, 100% 100%, 0% 100%) !important;
      clip-path: polygon(0% 0%, 100% 100%, 0% 100%) !important;
      left: 50% !important;
      transform: rotate(135deg) translateX(50%) !important;
      border-radius: 0 0 0 4px !important;
    }

    &.right {
      .mdc-tooltip__surface::after {
        top: 50% !important;
        left: -11px !important;
        transform: rotate(45deg) translateY(-50%) !important;
      }
    }
  }

  .right-tooltip > .mdc-tooltip__surface::after {
    left: 80% !important;
  }
}
