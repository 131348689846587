.zen-tooltip {
  border-radius: 6px !important;
  box-sizing: border-box;

  .mdc-tooltip__surface {
    font-weight: 500 !important;
    font-size: 12px !important;
    line-height: 16px !important;
    padding: 6px 8px !important;
    color: var(--zen-neutral-1400) !important;
    background-color: var(--zen-neutral-300) !important;
    text-align: center;
  }
}

.zen-over-popover {
  z-index: 1001 !important;
}

.zen-text-truncate-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.transparent-backdrop {
  background: transparent !important;
  z-index: 1002;
}

.zen-backdrop {
  background: rgba(0, 0, 0, 0.7) !important;
  z-index: 1001;
}

.zen-popover-reset {
  background-color: transparent !important;
  min-height: 0 !important;
  box-shadow: unset !important;
  border-radius: 100% !important;
}

.placement-stories-nudge {
  border-radius: 12px !important;
  .placement-hard-nudge {
    .titles {
      color: var(--zen-text-100);
      .icon-circle-bg {
        background: linear-gradient(
          113.93deg,
          rgba(127, 127, 127, 0.7) 11.39%,
          rgba(87, 87, 87, 0) 46.94%,
          rgba(255, 255, 255, 0.0001) 61.72%,
          rgba(255, 255, 255, 0.1) 99.01%
        ) !important;
        padding: 1px;
        .icon-circle {
          background: var(--zen-neutral-1200);
          background-blend-mode: luminosity;
        }
      }
      .title-text {
        width: 187px;
      }
    }
    .close-popover {
      color: var(--zen-text-100);
    }
  }
}

.margin-left-100 {
  margin-left: -100px;
}

.zen-sidebar-dialog.cdk-overlay-pane {
  padding: 0;
  animation: sidebarSlideInFromRight 300ms ease-in;
  height: 100vh !important;
  position: fixed !important;
  top: 0 !important;
  right: 0 !important;

  &.left-overlay {
    left: 0px !important;
    right: unset !important;
    animation: sidebarSlideInFromLeft 300ms ease-in;
  }

  .mdc-dialog__container {
    scale: 1 !important;
    transform: none !important;
    opacity: 1 !important;
    transition-duration: 0ms !important;
    .mat-mdc-dialog-surface.mdc-dialog__surface {
      border-radius: 0 !important;
      background-color: transparent !important;
      overflow: hidden !important;
    }
  }
}

.zen-practice-page-dialog.cdk-overlay-pane {
  padding: 0;
  animation: sidebarSlideInFromRight 300ms ease-in;
  height: 100vh !important;
  position: fixed !important;
  top: 0 !important;
  right: 0 !important;

  &.left-overlay {
    left: 0 !important;
    right: unset !important;
    animation: sidebarSlideInFromLeft 300ms ease-in;
  }

  .mdc-dialog__container {
    scale: 1 !important;
    transform: none !important;
    opacity: 1 !important;
    transition-duration: 0ms !important;
    .mat-mdc-dialog-surface.mdc-dialog__surface {
      border-radius: 20px 0 0 20px !important;
      overflow: hidden !important;
      background-color: transparent;
    }
  }
}
.zen-bottom-sheet-dialog.cdk-overlay-pane {
  padding: 0;
  max-height: 100vh !important;
  width: 100% !important;
  min-width: 360px !important;
  max-width: 428px !important;

  .mat-bottom-sheet-container {
    width: 100% !important;
    overflow: hidden !important;
    min-width: 100% !important;
    max-height: 95vh !important;
    border-radius: 20px 20px 0 0;
    background: unset !important;
    box-shadow: unset !important;
  }
}

.unified-profile-bottom-sheet.cdk-overlay-pane {
  padding: 0;
  max-height: 100vh !important;
  width: 100% !important;
  min-width: 360px !important;
  max-width: 428px !important;

  .mat-bottom-sheet-container {
    width: 100% !important;
    overflow: hidden !important;
    min-width: 100% !important;
    max-height: 80vh !important;
    border-radius: 16px 16px 0 0;
    background: unset !important;
    box-shadow: unset !important;
    padding: 0 !important;
  }
}

.zen-fullscreen-dialog.cdk-overlay-pane {
  padding: 0;
  max-height: 100vh !important;
  min-height: 100vh !important;
  height: 100vh !important;
  width: 100% !important;
  min-width: 360px !important;
  max-width: 428px !important;

  .mdc-dialog__container {
    scale: 1 !important;
    transform: none !important;
    opacity: 1 !important;
    transition-duration: 0ms !important;
    .mat-mdc-dialog-surface.mdc-dialog__surface {
      border-radius: 0 !important;
      overflow: hidden !important;
    }
  }
}

@keyframes sidebarSlideInFromRight {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes sidebarSlideInFromLeft {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@media (max-width: 1279px) {
  .zen-fullscreen-dialog.cdk-overlay-pane {
    max-height: 100% !important;
    min-height: 100% !important;
    height: 100% !important;
  }
}
