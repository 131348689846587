@keyframes zenFastShimmerSmall {
  0% {
    left: -50%;
  }
  33.333%,
  100% {
    left: 102%;
  }
}

@keyframes zenFastShimmerLarge {
  0% {
    left: calc(-50% + 11px);
  }
  33.333%,
  100% {
    left: calc(100% + 11px);
  }
}
